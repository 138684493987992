function initAmdSteps() {
    let el = document.getElementById('amd-steps');
    let timer = 0;

    if(!el) {
        return;
    }

    let toggles = el.querySelectorAll('[data-step-toggle]');
    let steps = Array.from(el.querySelectorAll('[data-step'));

    for(let toggle of toggles) {
        toggle.addEventListener('click', () => {
            if(timer) {
                window.clearTimeout(timer);
                timer = 0;
            }

            let stepIndex = toggle.dataset.stepToggle;
            let toggleStep = el.querySelector(`[data-step="${stepIndex}"]`);
            let openCount = el.querySelector('.step-open');
            closeAllSteps(steps.filter((step) => step !== toggleStep));
            
            timer = window.setTimeout(() => {
                if(Number(stepIndex) >= steps.length / 2) {
                    el.classList.add('expand-to-left');
                }
                else {
                    el.classList.remove('expand-to-left');
                }

                toggleStep?.classList?.toggle('step-open');
            }, openCount ? 300 : 0); 
        });
    }
}

function closeAllSteps(steps) {
    for(let step of steps) {
        step.classList.remove('step-open');
    }
}


export default initAmdSteps;