let list_accordion_toggles = document.querySelectorAll('.list-accordion-toggle');
let list_accordion_items = document.querySelectorAll('.list-accordion-item');

if (list_accordion_toggles.length) {
    list_accordion_toggles.forEach(toggle => {
        let parent_item = toggle.closest('.list-accordion-item');

        toggle.addEventListener('click', e => {
            e.preventDefault();
            
            list_accordion_items.forEach(item => {
                if (item !== parent_item) {
                    item.classList.remove('is-open');
                }
            });

            parent_item.classList.toggle('is-open');
        });
    });
}

document.getElementById("read-more-btn")?.addEventListener(
  "click",
  function(event) {
    if (event.target.value === "Read their story") {
      event.target.value = "Read Less";
    } else {
      event.target.value = "Read their story";
    }
  },
  false
);