const privacyForm = document.querySelector('.privacy-form');
const stateOptions = {
    CA: [
        'Right to Know',
        'Right to Delete',
        'Right to Opt-Out of Data Sale',
        'Right to Correct Inaccuracies',
        'Right to Opt-Out of Behavioral Advertising/Targeted Advertising',
        'Right to Opt-Out of Automated Decision Making/Profiling',
        'Right to Opt-Out of Use of Sensitive Data'
    ],
    CO: [
        'Right to Know',
        'Right to Delete',
        'Right to Opt-Out of Data Sale',
        'Right to Correct Inaccuracies',
        'Right to Opt-Out of Behavioral Advertising/Targeted Advertising',
        'Right to Opt-Out of Automated Decision Making/Profiling',
        'Right to Opt-Out of Use of Sensitive Data',
        'Right to Opt-In to Use of Sensitive Data'
    ],
    CT: [
        'Right to Know',
        'Right to Delete',
        'Right to Opt-Out of Data Sale',
        'Right to Correct Inaccuracies',
        'Right to Opt-Out of Behavioral Advertising/Targeted Advertising',
        'Right to Opt-Out of Automated Decision Making/Profiling',
        'Right to Opt-Out of Use of Sensitive Data',
        'Right to Opt-In to Use of Sensitive Data'
    ],
    VA: [
        'Right to Know',
        'Right to Delete',
        'Right to Opt-Out of Data Sale',
        'Right to Opt-Out of Behavioral Advertising/Targeted Advertising',
        'Right to Opt-Out of Automated Decision Making/Profiling',
        'Right to Opt-In to Use of Sensitive Data'
    ]
}

if(privacyForm) {
    const modeField = privacyForm.querySelector('.request-type-field select');
    const stateField = privacyForm.querySelector('.state-field select');
    const otherStateField = privacyForm.querySelector('[name="input_14.4"]');
    const changeToError = privacyForm.querySelector('.gfield_error.change-to');

    if(modeField) {
        setMode(modeField);
        modeField.addEventListener('change', function() {
            setMode(modeField);
        });
    }
    
    if(stateField && modeField) {
        setModeOptions(modeField, stateOptions[stateField.value] || []);

        if(otherStateField) {
            otherStateField.value = stateField.value;
        }

        stateField.addEventListener('input', function(e) {
            setModeOptions(modeField, stateOptions[e.target.value] || []);

            if(otherStateField) {
                otherStateField.value = e.target.value;
            }
        });
    }

    if(changeToError) {
        privacyForm.querySelector('.change-to-error')?.classList.add('show');
    }
}

function setModeOptions(modeField, options) {
    const initialValue = modeField.value;
    modeField.innerHTML = '';
    options.forEach((option) => {
        let optionEl = document.createElement('option');
        optionEl.innerHTML = option;
        modeField.appendChild(optionEl);
    });

    window.setTimeout(() => {
        modeField.value = initialValue;
        setMode(modeField);
    });
}

function setMode(modeField) {
    const changeFromFields = privacyForm.querySelectorAll('.change-from');
    const changeToFields = privacyForm.querySelectorAll('.change-to');

    if(modeField.value === 'Right to Correct Inaccuracies') {
        updateFromFields(true, changeFromFields);
        updateToFields(true, changeToFields);
        toggleDividers(true);
    }
    else {
        updateFromFields(false, changeFromFields);
        updateToFields(false, changeToFields);
        toggleDividers(false);
    }
}

function updateFromFields(addTo, changeFromFields) {
    changeFromFields.forEach(field => {
        if(addTo) {
            addChangeFrom(field);
        }
        else {
            removeChangeFrom(field);
        }
    });
}

function updateToFields(addFrom, changeToFields) {
    changeToFields.forEach(field => {
        if(addFrom) {
            addChangeTo(field);
        }
        else {
            removeChangeTo(field);
        }
    });
}

function removeChangeFrom(el) {
    const label = el.querySelector(':scope > .gfield_label');

    if(label) {
        label.innerHTML = label.innerHTML.replace('<strong>Change From: </strong>', '');
    }
}

function removeChangeTo(el) {
    el.style.display = 'none';
    const label = el.querySelector(':scope > .gfield_label');
    
    if(label) {
        label.innerHTML = label.innerHTML.replace('<strong>Change To: </strong>', '');
    }
}

function addChangeFrom(el) {
    const label = el.querySelector(':scope > .gfield_label');

    if(label) {
        label.innerHTML = `<strong>Change From: </strong>${label.innerHTML.replace('<strong>Change From: </strong>', '')}`;
    }
}

function addChangeTo(el) {
    el.style.display = 'block';
    const label = el.querySelector(':scope > .gfield_label');
    const inputs = el.querySelectorAll(':scope input, :scope select');
 
    if(label) {
        label.innerHTML = `<strong>Change To: </strong>${label.innerHTML.replace('<strong>Change To: </strong>', '')}`;
    }

    inputs.forEach((input) => {
        input.removeAttribute('disabled');
    });
}

function toggleDividers(visible) {
    const toDividers = privacyForm.querySelectorAll('.change-to-divider');
    const fromDividers = privacyForm.querySelectorAll('.change-from-divider');

    toDividers.forEach(divider => {
        divider.style.display = visible ? 'block' : 'none';
    });

    fromDividers.forEach(divider => {
        divider.style.display = visible ? 'none' : 'block';
    });
}