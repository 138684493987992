import nextUntil from '../lib/nextUntil';
import Expandset from '../lib/Expandset';

const accordionForms = document.querySelectorAll('.gform-section-accordions');

accordionForms.forEach(form => {
    const accordionSections = form.querySelectorAll('.section-to-accordion');
    console.log('sections', accordionSections);
    const formBody = form.querySelector('.gform_body .gform_fields');
    
    const accordionWraper = document.createElement('li');
    accordionWraper.classList.add('accordion');
    const Formid = Math.floor(Math.random() * 90000) + 10000;

    accordionWraper.setAttribute('data-expandset', Formid);


    accordionSections.forEach((section, index) => {
        const id = Math.floor(Math.random() * 90000) + 10000;

        const open = section.classList.contains('opened');

        // Get the normal fields between our current section and our next section
        const fieldsBetween = nextUntil(section, '.section-to-accordion');
        console.log('fields', fieldsBetween);

        // Generate 5 digit random id

        // region Build the accordion markup
        const accordionItem = document.createElement('div');
        accordionItem.classList.add('accordion-item');
        accordionItem.classList.toggle('is-open', open);
        accordionWraper.appendChild(accordionItem);

        const accordionHeader =  document.createElement('button');
        accordionHeader.setAttribute('type', 'button');
        accordionHeader.setAttribute('data-toggle-expandset', id);
        accordionHeader.classList.add('accordion-header');
        accordionHeader.classList.add('scroll-anchor');
        accordionHeader.classList.toggle('open', open);
        accordionItem.appendChild(accordionHeader);

        const accordionBody = document.createElement('div');
        accordionBody.setAttribute('data-expandset-body', id);
        // accordionBody.classList.add('accordion-body');
        accordionBody.classList.toggle('open', open);
        accordionItem.appendChild(accordionBody);
        // endregion

        // Take the title of our section and move it into the header.
        const title = section.querySelector('.gsection_title');
        const accordionTitle = document.createElement('h4');
        accordionTitle.innerText = title.innerText;
        
        accordionHeader.appendChild(accordionTitle);
        section.remove();

        // Put our fields inside the accordion body
        fieldsBetween.forEach(field => {
            accordionBody.appendChild(field);
        });

    });

    formBody.appendChild(accordionWraper);

    
    // new Expandset(accordionWraper, {
    //     accordion: true,
    // });
});
