const COOKIE_START = 'promoalert';
const COOKIE_EXPIRES = 'path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; Secure';

export function initPromoAlert() {
  const promoAlertEl = document.querySelector('[data-promo-alert]');

  if(!promoAlertEl) {
    return;
  }

  const slug = promoAlertEl.dataset.promoSlug;
  const maxViewCount = promoAlertEl.dataset.hideAfter ? parseInt(promoAlertEl.dataset.hideAfter) : false;

  if(maxViewCount) {
    // check cookie
    let views = getCookieValue(slug);

    if(views < maxViewCount) {
      showAlert();
      setCookieValue(slug, views + 1);
    }
    else {
      hideAlert();
    }
  }
  else {
    showAlert();
  }

  promoAlertEl.querySelector('[data-close-promo-alert]')?.addEventListener('click', hideAlert);
}

function getCookieValue(slug) {
  const cookieKey = `${COOKIE_START + slug}`;
  const cookieValue = document.cookie
    ?.split('; ')
    ?.find(row => row.startsWith(cookieKey))
    ?.split('=')[1];
  if(cookieValue) {
    return parseInt(cookieValue);
  }
  else {
    return 0;
  }
}

function setCookieValue(slug, value) {
  const cookieKey = `${COOKIE_START + slug}`;
  document.cookie = `${cookieKey}=${value}; ${COOKIE_EXPIRES}`;
}

function showAlert() {
  document.querySelector('[data-promo-alert]').classList.remove('hidden');
}

function hideAlert(e) {
  e?.preventDefault();
  document.querySelector('[data-promo-alert]').remove();
}