let play_buttons = document.querySelectorAll('[data-play-video]');

let Vidyard;
let YouTube;

if(play_buttons.length) {
    let vidyard_promise = import(/* webpackChunkName: "vidyard" */ '../lib/Vidyard.js').then(({default: vy}) => { Vidyard = vy; });
    let youtube_promise = import(/* webpackChunkName: "youtube" */ '../lib/YouTube.js').then(({default: yt}) => { YouTube = yt; });
    Promise.all([vidyard_promise, youtube_promise]).finally(() => {
        play_buttons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
        
                let videoId = e.target.dataset.playVideo;
        
                if(videoId.length === 11) {
                    YouTube.getPlayerByVideoId(videoId)?.play();
                }
                else {
                    Vidyard.getPlayerByVideoId(videoId)?.play();
                }
            });
        });
    });
}
