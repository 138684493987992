/**
 * 
 * Adopted from https://gomakethings.com/how-to-get-all-sibling-elements-until-a-match-is-found-with-vanilla-javascript/
 * 
 * @param {*} elem 
 * @param {*} selector 
 */

const nextUntil = (elem, selector) => {
    const siblings = [];

    // Get the next sibling element
    elem = elem.nextElementSibling;

    // As long as a sibling exists
    while (elem) {
        // If we've reached our match, bail
        console.log('elem matches ', elem.matches(selector));
        if (elem.matches(selector)) break;

        // Otherwise, push it to the siblings array
		siblings.push(elem);

        // Get the next sibling element
		elem = elem.nextElementSibling;
    }

    return siblings;
};

export default nextUntil;
