import "regenerator-runtime/runtime";

import './components/HomeHero';
import JumpNav from './lib/JumpNav';
import './components/PlayVideo';
import scrollToContainingBlock from './lib/ScrollToContainingBlock.js';
import './components/GoBack';
import './components/AlertBar';
import './components/RambleChat';
import './components/Inview';
import './components/Sliders';
import './components/SlotSlider';
import './components/Submenu.js';
import './components/LazyLoadVideo';
import './components/ListAccordion';
import './components/MobileTables';
import './components/RestrictToState';
import './components/PrivacyForm';
import './components/overlayImgToggle';
import { initPromoAlert } from './components/PromoAlert';
import './components/GformAccordions.js';
import initAmdSteps from "./components/AmdStep";
import "./components/oai-popup.js";
import { initShowMorePosts } from './components/ShowMorePosts.js';
import SiteHeader from './components/site-header.js';

//static includes

window.addEventListener('DOMContentLoaded', () => {
    // dynamically load quicklinks
    if(document.querySelectorAll('[data-quick-links]').length) {
        import(/* webpackChunkName: "quickLinks" */ './lib/QuickLinks').then(({default : initQuickLinks}) => {
            initQuickLinks();
        });
    }

    // dynamically load TestimonialsMasonry
    if(document.querySelectorAll('.masonry').length) {
        import(/* webpackChunkName: "testimonialsMasonry" */ './components/TestimonialsMasonry');
    }

    // dynamically load Vidyard
    if(document.querySelectorAll('[data-vidyard-embed]').length) {
        import(/* webpackChunkName: "vidyard" */ './lib/Vidyard').then(({default: Vidyard}) => {
            /* init Vidyard */
            Vidyard.initVideos();
        });
    }

    // dynamically load YouTube
    if(document.querySelectorAll('[data-youtube-embed]').length) {
        import(/* webpackChunkName: "youtube" */ './lib/YouTube').then(({default: YouTube}) => {
            /* init YouTube */
            YouTube.initVideos();
        });
    }

    // dynamically load tippy
    if(document.querySelectorAll('[data-tippy-content]')) {
        import(/* webpackChunkName: "tippy" */ 'tippy.js').then(({default: tippy, roundArrow}) => {
            /* tooltips via tippy.js https://atomiks.github.io/tippyjs/ */
            tippy('[data-tippy-content]', {
                animation: "scale-subtle",
                theme: "dark-border",
                arrow: roundArrow
            });
        });
    }

    setTimeout(() => {
        if(window.location.hash === '#banners'){
            window.history.pushState("", "", '/banners');
        }
    }, 2000)

    // Page Header
    let options = {
        container: document.querySelector('[page]') || document.querySelector('html'),
        el: document.querySelector('[page-header]'),
        toggle_el: document.querySelector('[page-header-hamburger]'),
        menu_item_els: document.querySelectorAll('[page-header-menu-item]'),
        search_toggle_el: document.querySelector('[page-header-search-dropdown]')
    }

    // Jump Nav
    let jump_nav = new JumpNav({
        el: document.querySelector('[jump-nav]'),
        static_el: document.querySelector('[jump-nav-static]')
    });

    // Expandsets
    let expandsets = document.querySelectorAll('[data-expandset]');
    // dynamically load Expandset
    if(expandsets.length) {
        import(/* webpackChunkName: "expandset" */ './lib/Expandset').then(({default: Expandset}) => {
            expandsets.forEach(expandset => {
                new Expandset(expandset, {
                    accordion: true
                });
            });
        });
    }

    // Dropdowns
    let dropdowns = document.querySelectorAll("[data-control='dropdown']");
    // dynamically load Dropdowns
    if(dropdowns.length) {
        import(/* webpackChunkName: "dropdown" */ './lib/Dropdown').then(({default: Dropdown}) => {
            dropdowns.forEach(dropdown => {
                new Dropdown(dropdown);
            });
        });
    }

    // dynamically load ContactForm and Newsletter form if form is inview
    if ("IntersectionObserver" in window) {
        let contact_form = document.getElementById('contact-form-block');
        let newsletter_form = document.getElementById('newsletter-form-block');
        let form_observer = new IntersectionObserver(function(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id === 'contact-form-block') {
                    import(/* webpackChunkName: "contactForm" */ './components/ContactForm').then(({default: ContactForm}) => {
                        new ContactForm('contact-form');
                    });
                    form_observer.unobserve(entry.target);
                }

                if (entry.isIntersecting && entry.target.id === 'newsletter-form-block') {
                    import(/* webpackChunkName: "newsletterSignup" */ './components/NewsletterSignup').then(({default: NewsletterSignup}) => {
                        new NewsletterSignup('newsletter-form');
                    });
                    form_observer.unobserve(entry.target);
                }
            });
        });

        if(contact_form) {
            form_observer.observe(contact_form);
        }

        if(newsletter_form) {
            form_observer.observe(newsletter_form);
        }
    }

    /* init tabsets */
    let tabsets = document.querySelectorAll('[data-tabset]');
    let tabset_blocks = document.querySelectorAll('[data-tabset-block]');
    // dynamically load tabset
    if(tabsets.length || tabset_blocks.length) {
        import(/* webpackChunkName: "tabset" */ './lib/Tabset').then(({default: Tabset}) => {
            tabsets.forEach((tabset) => {
                new Tabset(tabset, {
                    transition_time: 300,
                });
            });

            tabset_blocks.forEach((tabset) => {
                new Tabset(tabset, {
                    transition_time: 0
                });
            });
        });
    }

    /* init form validation */
    let forms = document.querySelectorAll('[data-form-validate]');
    // dynamically load Form
    if(forms.length) {
        import(/* webpackChunkName: "form" */ './components/Form').then(({default: Form}) => {
            forms.forEach((form) => {
                new Form(form);
            });
        });
    }

    /* init post show more button */
    initShowMorePosts();
});

/* init promo alert */
initPromoAlert();

/* init amd steps */
initAmdSteps();

SiteHeader.init();

const handleTooltipPosition = function( tooltip ) {
	// Get position of the tooltip
	const tooltipPosition = tooltip.getBoundingClientRect()?.right;

	// Check content of tooltip fron data-tooltip attribute
	const tooltipContent = tooltip.getAttribute( 'data-tooltip' );

	// Get the width of the tooltip
	const tooltipWidth = tooltipContent?.length < 35 ? 150 : 300;

	if ( tooltipPosition < tooltipWidth ) {
		// Means tooltip is going out of the screen on left ride, we need to move it to right side
		tooltip?.style?.setProperty(
			'--tooltip-right-position',
			`-${ tooltipWidth - tooltipPosition }px`,
		);
	} else {
		// Else reset tooltip to right edge
		tooltip?.style?.setProperty( '--tooltip-right-position', '0px' );
	}
};
