function initPopup() {
    const oaiPopup = document.getElementById('overlay-img');

    if(!oaiPopup) {
        return;
    }

    const closeButtons = oaiPopup.querySelectorAll('[data-close]');
    for(let button of closeButtons) {
        button.addEventListener('click', () => {hidePopup(oaiPopup)})
    }

    const yesButton = oaiPopup.querySelector('[data-button="yes"]');
    if(yesButton) {
        yesButton.addEventListener('click', () => onYes(oaiPopup));
    }

    const noButton = oaiPopup.querySelector('[data-button="no"]');
    if(noButton) {
        noButton.addEventListener('click', () => onNo(oaiPopup));
    }

    showPopup(oaiPopup);
}

//window.addEventListener('load', initPopup);
initPopup();

function showPopup(popup) {
    popup.classList.add('show');
}

function hidePopup(popup) {
    popup.classList.remove('show');
}

function onYes(popup) {
    const ctaButton = popup.querySelector('[data-button="cta"]');
    ctaButton.href = ctaButton.href.replace('willAttend=no', 'willAttend=yes');
    popup.classList.add('show-yes');
    popup.classList.remove('show-no');
    switchStep(popup, 2);
}

function onNo(popup) {
    const ctaButton = popup.querySelector('[data-button="cta"]');
    ctaButton.href = ctaButton.href.replace('willAttend=yes', 'willAttend=no');
    popup.classList.add('show-no');
    popup.classList.remove('show-yes');
    switchStep(popup, 2);
}

function switchStep(popup, activeStep) {
    const step1 = popup.querySelector('[data-step="1"]');
    const step2 = popup.querySelector('[data-step="2"]');

    let inStep = step1;
    let outStep = step2;
    
    if(activeStep === 2) {
        inStep = step2;
        outStep = step1;
    }

    inStep.classList.add('active');
    outStep.classList.remove('active');
}