/*
 * Header JS.
 *
 * @type {Object}
 */
const SiteHeader = {
	/**
	 * Initialize.
	 *
	 * @return {void}
	 */
	init() {
		this.searchButton = document.getElementById( 'toggle-header-search' );
		this.searchInput = document.getElementsByClassName(
			'b2b-autotrader2024-search-form__input'
		)[ 0 ];

		this.searchCloseButton = document.getElementById( 'search-close' );
		this.searchForm = document.getElementById( 'header-search-bar' );

		this.header = document.getElementById( 'masthead' );

		this.burgerMenu = document.getElementById( 'primary-header__menu-button' );
		this.mobileMenu = document.getElementById( 'site-navigation' );

		this.subMenuIcons = document.querySelectorAll(
			'.menu-item-has-children > a'
		);

		this.innerSubMenu = document.querySelectorAll(
			'.is-mega-menu-heading .has-child-menu-icon'
		);

		this.innerMegaMenu = document.querySelectorAll(
			'.child-menu-grid-layout .is-mega-menu-heading.menu-list-layout.autotrader-menu-list'
		);

		this.addEvents();
		this.openChildMenu();
		this.focusPrimaryMenu();
		this.addColumnsToMenuItem();
	},

	/**
	 * Add events.
	 *
	 * @return {void}
	 */
	addEvents() {
		this.searchButton?.addEventListener( 'click', () => {
			this.openSearchButton();
		} );

		this.searchCloseButton?.addEventListener( 'click', () =>
			this.closeSearchBar()
		);

		window.addEventListener( 'click', ( event ) => {
			let isClickInsideForm = true;

			if ( null !== this.searchForm && null !== this.searchButton ) {
				isClickInsideForm =
					this.searchForm?.contains( event.target ) ||
					this.searchButton?.contains( event.target ) ||
					event.target.classList?.contains( 'accordion-tab-title' );
			}

			if ( ! isClickInsideForm ) {
				this.closeSearchBar();
			}
		} );

		window.addEventListener( 'scroll', () => this.hideTopMenu() );

		this.burgerMenu?.addEventListener( 'click', () => this.moveBurgerMenu() );

		document.addEventListener( 'keydown', ( event ) => {
			let isEscape = false;

			if ( 'key' in event ) {
				isEscape = 'Escape' === event.key || 'Esc' === event.key;
			} else {
				isEscape = 27 === event.keyCode;
			}

			if ( isEscape ) {
				this.closeSearchBar();
			}
		} );

		if ( 1200 >= window.innerWidth ) {
			this.addBackLink();
		}

		window.addEventListener( 'resize', () => {
			if ( 1200 >= window.innerWidth ) {
				this.addBackLink();
			}

			if ( 1201 <= window.innerWidth ) {
				this.closeMobileMenu();
				this.closeInnerMenu();
				this.removeBackLink();
				this.resetInnerMenu();
			}
		} );

		this.innerSubMenu?.forEach( ( subMenu ) => {
			subMenu.addEventListener( 'click', ( event ) => {
				this.openInnerChildMenu( event );
			} );
		} );
	},

	/**
	 * Open search bar.
	 *
	 * @return {void}
	 */
	openSearchButton() {
		this.closeMobileMenu();
		this.closeInnerMenu();
		this.resetInnerMenu();

		this.searchForm?.classList.add( 'header-search-bar--active' );
		this.searchInput.value = '';
		setTimeout( () => {
			this.searchInput.focus();
		}, 200 );
	},

	/**
	 * Closes search bar.
	 *
	 * @return {void}
	 */
	closeSearchBar() {
		if ( this.searchForm?.classList?.contains( 'header-search-bar--active' ) ) {
			this.searchButton.focus();
		}

		this.searchForm?.classList?.remove( 'header-search-bar--active' );
	},

	/**
	 * Hides top Menu.
	 *
	 * @return {void}
	 */
	hideTopMenu() {
		if ( 100 <= window.scrollY ) {
			this.header?.classList?.add( 'site-header--is-scrolling' );
			document.body.classList.add( 'page-is-scrolling' );
		} else {
			this.header?.classList?.remove( 'site-header--is-scrolling' );
			document.body.classList.remove( 'page-is-scrolling' );
		}
	},

	/**
	 * Moves Burger Menu.
	 *
	 * @return {void}
	 */
	moveBurgerMenu() {
		this.burgerMenu?.classList?.toggle( 'primary-header__menu-button--close' );
		this.mobileMenu?.classList?.toggle( 'mobile-main-menu--displayed' );
		document.body.classList.toggle( 'disable-scroll' );

		this.closeInnerMenu();
		this.resetInnerMenu();
	},

	/**
	 * Remove Burger Menu class to close mobile menu.
	 *
	 * @return {void}
	 */
	closeMobileMenu() {
		if ( this.mobileMenu?.classList?.contains( 'mobile-main-menu--displayed' ) ) {
			this.burgerMenu?.classList?.remove( 'primary-header__menu-button--close' );
			this.mobileMenu?.classList?.remove( 'mobile-main-menu--displayed' );
			document.body.classList.remove( 'disable-scroll' );
		}
	},

	/**
	 * Remove back link.
	 *
	 * @return {void}
	 */
	removeBackLink() {
		document.querySelectorAll( '.has-back-link' )?.forEach( function( backLink ) {
			backLink.remove();
		} );
	},

	/**
	 * Reset inner menu item classes.
	 *
	 * @return {void}
	 */
	resetInnerMenu() {
		setTimeout( () => {
			document.querySelector( '.primary-menu' )?.classList?.remove( 'oh-active' );
			document
				.querySelectorAll( '.primary-menu .sub-menu' )
				?.forEach( ( subMenu ) => {
					subMenu?.classList?.remove( 'active' );
					subMenu?.classList?.remove( 'oh-active' );
				} );
			document.querySelectorAll( '.oh-active' )?.forEach( function( link ) {
				link.classList.remove( 'oh-active' );
			} );
		}, 200 );
	},

	/**
	 * toggle the plus icon.
	 *
	 * @param {Object} element Event object.
	 *
	 * @return {void}
	 */
	togglePlusIcon( element ) {
		if ( ! element.classList.contains( 'mobile-main-menu__toggle--close' ) ) {
			document.querySelectorAll( '.sub-menu--active' )?.forEach( ( subMenu ) => {
				subMenu.classList.remove( 'sub-menu--active' );
			} );

			document
				.querySelectorAll( '.mobile-main-menu__toggle--close' )
				?.forEach( ( subMenu ) => {
					subMenu.classList.remove( 'mobile-main-menu__toggle--close' );
				} );
		}

		element?.classList?.toggle( 'mobile-main-menu__toggle--close' );
		element
			?.closest( 'li' )
			?.getElementsByTagName( 'ul' )[ 0 ]
			?.classList?.toggle( 'sub-menu--active' );
	},

	/**
	 * Close inner child menu on mobile.
	 *
	 * @return {void}
	 */
	closeInnerMenu() {
		setTimeout( () => {
			document.querySelectorAll( '.sub-menu--active' )?.forEach( ( subMenu ) => {
				subMenu.classList.remove( 'sub-menu--active' );
			} );

			document
				.querySelectorAll( '.mobile-main-menu__toggle--close' )
				?.forEach( ( subMenu ) => {
					subMenu.classList.remove( 'mobile-main-menu__toggle--close' );
				} );
		}, 200 );
	},

	/**
	 * Add back link to sub menu.
	 *
	 * @return {void}
	 */
	addBackLink() {
		const menuWrap = document.getElementById( 'primary-menu' );

		if ( null === menuWrap ) {
			return false;
		}

		const allMenuWithChild = menuWrap.querySelectorAll(
			'.menu-item-has-children'
		);

		if ( null === allMenuWithChild ) {
			return false;
		}

		allMenuWithChild.forEach( ( menu ) => {
			const anchorText = 'Back';
			const liEl = document.createElement( 'li' );
			const anchor = document.createElement( 'a' );

			liEl.classList.add( 'has-back-link' );
			anchor.classList.add( 'back' );
			anchor.setAttribute( 'href', '#' );
			anchor.textContent = anchorText;

			liEl.appendChild( anchor );

			if ( null === menu.querySelector( '.has-back-link' ) ) {
				menu.querySelector( '.sub-menu' ).prepend( liEl );
			}

			document.addEventListener( 'click', ( event ) => {
				if ( event.target.classList.contains( 'back' ) ) {
					event.preventDefault();
					event.target?.closest( '.sub-menu' )?.classList?.remove( 'active' );
					const previousElementSibling =
						event.target?.closest( '.sub-menu' )?.previousElementSibling;
					if ( previousElementSibling ) {
						previousElementSibling.classList.remove( 'is-menu-active' );
					}

					if ( null !== event.target.closest( '.oh-active' ) ) {
						event.target.closest( '.oh-active' ).classList.remove( 'oh-active' );
					}
				}
			} );
		} );
	},

	/**
	 * Open sub menu.
	 *
	 * @return {void}
	 */
	openChildMenu() {
		const menuWrap = document.getElementById( 'primary-menu' );

		if ( null === menuWrap ) {
			return false;
		}

		const allMenuWithChild = menuWrap.querySelectorAll(
			'.menu-item-has-children'
		);

		if ( null === allMenuWithChild ) {
			return false;
		}

		const debouncedClickHandler = ( event ) => {
			this.debounce( this.handleSubMenuClick( event ), 300 );
		};

		document.removeEventListener( 'click', debouncedClickHandler );
		document.addEventListener( 'click', ( event ) => debouncedClickHandler( event ) );
	},

	/**
	 * Handle submenu click.
	 *
	 * @param {Object} event The click event object.
	 *
	 * @return {void}
	 */
	handleSubMenuClick( event ) {
		if ( 1201 <= window.innerWidth ) {
			return false;
		}

		if ( event.target.matches( '.has-child-menu-icon' ) ) {
			event.preventDefault();
		}

		if (
			event.target.matches( '.menu-item-has-children > a + .has-child-menu-icon' ) &&
			! event.target.matches( '.menu-item-has-children > .static-to-dynamic + .has-child-menu-icon' )
		) {
			event.preventDefault();
			event.target.parentNode.parentNode.parentNode.scrollTo( 0, 0 );
			const parentWithSubMenu = event.target?.parentNode
				?.closest( '.menu-item-has-children' )
				?.querySelector( '.sub-menu' );
			const tabWrapper = event.target?.parentNode
				?.closest( '.menu-item-has-children' )
				?.querySelector( '.menu-tab-wrapper' );
			tabWrapper?.classList.toggle( 'is-menu-active' );
			parentWithSubMenu?.classList.toggle( 'active' );
			event.target?.parentNode?.parentNode?.parentNode?.classList?.add(
				'oh-active'
			);
			document.querySelector( '.primary-menu' )?.classList?.add( 'oh-active' );
		}
	},

	/**
	 * Handle Debounce.
	 *
	 * @param {Function} func  The function to be debounced.
	 * @param {number}   delay The amount of delay in milliseconds.
	 *
	 * @return {Function}      It returns a new function that limits the
	 *                         rate at which the func can be invoked
	 */
	debounce( func, delay ) {
		let timer;
		return function() {
			const context = this;
			const args = arguments;
			clearTimeout( timer );
			timer = setTimeout( () => {
				func.apply( context, args );
			}, delay );
		};
	},

	/**
	 * Highlight menu on keyboard navigation.
	 *
	 * @return {boolean|void} Toggle focus class on keyboard navigation.
	 */
	focusPrimaryMenu() {
		let i, len;
		const menu = document.querySelector( '.b2b-autotrader2024-main-navigation' );

		if ( ! menu ) {
			return false;
		}

		const links = menu.getElementsByTagName( 'a' );

		for ( i = 0, len = links.length; i < len; i++ ) {
			links[ i ].addEventListener( 'focus', toggleFocus, true );
			links[ i ].addEventListener( 'blur', toggleFocus, true );
			links[ i ].parentElement?.addEventListener(
				'mouseenter',
				removeFocusClassOnHover
			);
			links[ i ].parentElement?.addEventListener(
				'mouseleave',
				removeFocusClassOnHover
			);
		}

		function toggleFocus() {
			let self = this;

			// Remove the focus class from all menu items.
			removeFocusClassFromAll();

			while ( -1 === self.className.indexOf( 'primary-menu' ) ) {
				if ( 'li' === self.tagName.toLowerCase() ) {
					if ( -1 !== self.className.indexOf( 'focus' ) ) {
						self.className = self.className.replace( ' focus', '' );
					} else {
						self.className += ' focus';
					}
				}
				self = self.parentElement;
			}
		}

		function removeFocusClassOnHover() {
			// Remove the focus class when hovering over a different menu item.
			let self = this;

			while ( -1 === self.className.indexOf( 'primary-menu' ) ) {
				if ( 'li' === self.tagName.toLowerCase() ) {
					self.className = self.className.replace( ' focus', '' );
				}
				self = self.parentElement;
			}
		}

		function removeFocusClassFromAll() {
			const focusedItems = menu.querySelectorAll( '.focus' );
			focusedItems.forEach( ( item ) => {
				item.className = item.className.replace( ' focus', '' );
			} );
		}
	},

	openInnerChildMenu( event ) {
		event.preventDefault();
		const childElementWrapper = event.target
			?.closest( '.menu-item-has-children' )
			?.querySelector( '.sub-menu' );
		childElementWrapper?.classList.toggle( 'active' );
	},

	/**
	 * Add columns to the menu items inside a mega menu.
	 *
	 * It checks the number of items and assigns a class to each item
	 * accordingly. If there are 3 or less items, adds a class to make them
	 * appear in the same column. If there are more than 3 items, assigns a
	 * class to make the first half appear in the first column and the second
	 * half in the second column.
	 */
	addColumnsToMenuItem() {
		this.innerMegaMenu?.forEach( ( megaMenu ) => {
			const menuItems = Array.from( megaMenu.children[ 1 ].children );

			const totalItems = menuItems?.length;

			if ( totalItems <= 3 ) {
				menuItems?.forEach( ( menuItem ) => {
					menuItem.classList.add( 'first-column' );
				} );
			} else if ( totalItems > 3 && totalItems <= 6 ) {
				menuItems?.forEach( ( menuItem, index ) => {
					if ( index < 3 ) {
						menuItem.classList.add( 'first-column' );
					} else {
						menuItem.classList.add( 'second-column' );
					}
				} );
			} else {
				const half = Math.ceil( totalItems / 2 );

				menuItems?.forEach( ( menuItem, index ) => {
					if ( index < half ) {
						menuItem.classList.add( 'first-column' );
					} else {
						menuItem.classList.add( 'second-column' );
					}
				} );
			}
		} );
	},
};

export default SiteHeader;
