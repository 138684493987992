import Glide from '@glidejs/glide/dist/glide.esm';
import AutoHeight from '../lib/AutoHeight';

window.addEventListener('DOMContentLoaded', () => {
    // dynamically load youtube and vidyard if needed
    let Vidyard;
    if(document.querySelectorAll('[data-vidyard-embed]').length) {
       import(/* webpackChunkName: "vidyard" */ '../lib/Vidyard.js').then(({default: vy}) => { Vidyard = vy; });
    }

    let YouTube;
    if(document.querySelectorAll('[data-youtube-embed]').length) {
       import(/* webpackChunkName: "youtube" */ '../lib/YouTube.js').then(({default: yt}) => { YouTube = yt; });
    }

    // Carousels
    let sliders = document.querySelectorAll("[data-slider]");
    sliders.forEach(slider => {
        let slider_name = slider.dataset.slider;
        let slider_el = slider;
        let autoplay = slider_el?.dataset?.autoplay || false;

        slider = new Glide(slider_el, {
            animationDuration: 600,
            autoplay: Number(autoplay),
            hoverpause: false
        });

        slider.on('move', () => {
            Vidyard?.pauseAllVideos();
            YouTube?.pauseAllVideos();
        });

        var controls = document.querySelectorAll('[data-slider-link="' + slider_name + '"]');

        if (controls) {
            controls.forEach(control => {
                control.addEventListener('click', e => {
                    e.preventDefault();
                    let dir = control.dataset.sliderDir;

                    slider.go(dir);
                    
                    var bodyRect = document.body.getBoundingClientRect(),
                        elemRect = slider_el.getBoundingClientRect(),
                        offset   = elemRect.top - bodyRect.top - document.querySelector('[page-header]').offsetHeight - 30;

                    window.scrollTo({
                        top: offset,
                        behavior: 'smooth'
                    });
                });
            });
        }

        // Mount!
        slider.mount({ AutoHeight });
    });

    let post_sliders = document.querySelectorAll("[data-post-slider]");

    post_sliders.forEach(post_slider => {
        let slider = new Glide(post_slider, {
            animationDuration: 600,
            perView: 3,
            gap: 30,
            bound: true,
            dragThreshold: false,
            breakpoints: {
                767: {
                    type: 'carousel',
                    autoplay: 3000,
                    dragThreshold: 80,
                    perView: 1,
                    gap: 15,
                    peek: { before: 45, after: 15 }
                }
            }
        }).mount();
    });

    // deferred loading of sliders on inview
    if ("IntersectionObserver" in window) {
        let post_sliders = document.querySelectorAll("[data-deferred-slider]");
        let slider_observer = new IntersectionObserver(function(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting && typeof entry.target.dataset.deferredSlider !== undefined) {
                    initDeferredSlider(entry.target)
                    slider_observer.unobserve(entry.target);
                }
            });
        });

        post_sliders.forEach((slider) => {
            slider_observer.observe(slider);
        });
    }
});

function initDeferredSlider(slider) {
    let slider_el = slider;
    let autoplay = slider_el?.dataset?.autoplay || false;

    slider = new Glide(slider_el, {
        animationDuration: 600,
        autoplay: Number(autoplay),
        hoverpause: false
    });

    // Mount!
    slider.mount({ AutoHeight });
}