import AtdHiddenHeight from './AtdHiddenHeight';

let YouTube;
let Vidyard;

class Expandset {
    constructor(el = null, options = {}) {
        if(!el) {
            return;
        }

        this.window_width = window.innerWidth;

        this.expandset_el = el;

        // make sure we have an id for this expandset
        this.expandset_el.id = this.expandset_el.id || `atd-expandset-${Expandset.expandsets.length + 1}`;

        this.options = Object.assign({
            transition_time: 0,
            accordion: false,
            keep_open: typeof this.expandset_el.dataset.keepOpen !== 'undefined' ? true : false
        }, options);

        this.toggleExpandsetListener = (e) => {
            if(e.target.dataset.toggleExpandset) {
                e.preventDefault();
                window.setTimeout(() => {
                    this.toggleExpandset(e.target.dataset.toggleExpandset, e);
                }, 0);
                
                return;
            }
        }

        this.setEventListeners();

        Expandset.expandsets.push(this);

        // dynamically load youtube and vidyard if needed
        if(document.querySelectorAll('[data-vidyard-embed]').length) {
            import('../lib/Vidyard.js').then(({default: vy}) => { Vidyard = vy; });
        }

        if(document.querySelectorAll('[data-youtube-embed]').length) {
            import('../lib/YouTube.js').then(({default: yt}) => { YouTube = yt; });
        }
    }

    toggleExpandset(expand_id, e) {
        let expand_bodies = [];
        let expand_toggles = [];
        if(this.expandset_el.matches('[data-expandset] [data-expandset]')) {
            expand_bodies = this.expandset_el.querySelectorAll(`[data-expandset-body="${expand_id}"]`);
            expand_toggles = this.expandset_el.querySelectorAll(`[data-toggle-expandset="${expand_id}"]`);
        }
        else {
            expand_bodies = this.expandset_el.querySelectorAll(`[data-expandset-body="${expand_id}"]:not([data-expandset] [data-expandset] [data-expandset-body])`);
            expand_toggles = this.expandset_el.querySelectorAll(`[data-toggle-expandset="${expand_id}"]:not([data-expandset] [data-expandset] [data-toggle-expandset])`);
        }


        if(!expand_bodies) {
            return false;
        }

        expand_bodies.forEach((expand_body) => {
            let fullHeight = AtdHiddenHeight(expand_body);

            if(this.options.keep_open && expand_body.classList.contains('open') && this.window_width > 767) {
                return;
            }

            expand_body.style.height = `0px`;

            if(this.options.accordion && this.window_width > 767) {
                this.closeAll(expand_body);
            }

            expand_body.classList.add('transitioning');

            window.setTimeout(() => {
                expand_body.classList.toggle('open');
                if(expand_body.classList.contains('open')) {
                    //expand_body.style.height = `${fullHeight}px`;
                    expand_body.style.height = 'auto';
                }
                else {
                    expand_body.style.height = `0px`;
                }

                window.setTimeout(() => {
                    expand_body.classList.remove('transitioning');
                }, this.options.transition_time);
            }, 0);
        });
        
        if (this.window_width > 767) {
            expand_toggles.forEach(toggle => {
                if((this.options.keep_open && toggle.classList.contains('open')) || typeof toggle.dataset.toggleExpandsetNoStyle !== 'undefined') {
                    return;
                }
                
                toggle.classList.toggle("open");
            });
        } else {
            e.target.classList.toggle('open');
        }

        if(e.target.classList.contains('open') && e.target.classList.contains('scroll-anchor')) {
            e.target.scrollIntoView({behavior: 'auto', block: 'start'});
            window.setTimeout(() => {
                // let position =  e.target.getBoundingClientRect();
                // window.scrollTo(position.left, position.top + window.scrollY - 180);

                e.target.scrollIntoView({behavior: 'auto', block: 'start'});
            },this.options.transition_time ? this.options.transition_time : 50);
        }

        YouTube?.pauseAllVideos();
        Vidyard?.pauseAllVideos();
    }

    closeExpandset(expand) {
        if(!expand) {
            return false;
        }

        let expand_toggles = this.expandset_el.querySelectorAll(`[data-toggle-expandset="${expand.dataset.expandsetBody}"]`);

        expand_toggles.forEach(toggle => {
            if(typeof toggle.dataset.toggleExpandsetNoStyle === 'undefined') {
                toggle.classList.remove('open');
            }
        });

        expand.classList.add('transitioning');

        window.setTimeout(() => {
            expand.classList.remove('open');
            expand.style.height = `0px`;

            this.expandset_el.querySelectorAll(`[data-toggle-expandset="${expand.dataset.expandsetBody}"]`).forEach((toggle) => {
                if(typeof toggle.dataset.toggleExpandsetNoStyle === 'undefined') {
                    toggle.classList.remove('open');
                }
            });
            expand_toggles.forEach((toggle) => {
                if(typeof toggle.dataset.toggleExpandsetNoStyle === 'undefined') {
                    toggle.classList.remove('open');
                }
            });

            window.setTimeout(() => {
                expand.classList.remove('transitioning');
            }, this.options.transition_time);
        }, 0);

        YouTube?.pauseAllVideos();
        Vidyard?.pauseAllVideos();
    }

    closeAll(except) {
        let expands = [];
        if(this.expandset_el.matches('[data-expandset] [data-expandset]')) {
            expands = this.expandset_el.querySelectorAll('[data-expandset-body]');
        }
        else {
            expands = this.expandset_el.querySelectorAll('[data-expandset-body]:not([data-expandset] [data-expandset] [data-expandset-body])');
        }
        
        expands.forEach((expand) => {
            if(expand.classList.contains('open') && expand.dataset.expandsetBody !== except.dataset.expandsetBody) {
                this.closeExpandset(expand);
            }
        });
    }

    setEventListeners() {
        let expand_toggles = [];
        if(this.expandset_el.matches('[data-expandset] [data-expandset]')) {
            expand_toggles = this.expandset_el.querySelectorAll(`[data-toggle-expandset]`);
        }
        else {
            expand_toggles = this.expandset_el.querySelectorAll(`[data-toggle-expandset]:not([data-expandset] [data-expandset] [data-toggle-expandset])`);
        }

        expand_toggles.forEach((toggle) => {
            toggle.addEventListener('click', this.toggleExpandsetListener);
        });


    }

    static getExpandsetById(id) {
        let match = false;

        Expandset.expandsets.forEach((expandset) => {
            if(!match) {
                match = expandset.expandset_el.id === id ? expandset : false;
            }
        });

        return match;
    }
}

Object.defineProperty(Expandset, 'expandsets', {
    get: function() { return this.hasOwnProperty('_expandsets') ? this._expandsets : void 0; },
    set: function(v) { this._expandsets = v; }
});

Expandset.expandsets = [];

export default Expandset;
