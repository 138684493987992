class JumpNav {
    constructor(options) {
        this.state = {
            below_nav: false
        }

        this.options = options;
        this.options.body = document.querySelector('body');

        if (this.options.el && this.options.static_el) {
            let clone = this.options.static_el.cloneNode(true);
            clone.removeAttribute('jump-nav-static');
            this.options.el.appendChild(clone);
            window.requestAnimationFrame(() => { this.checkScroll(); });
        }
    }

    checkScroll() {
        this.state.below_nav = this.options.static_el.getBoundingClientRect().top < (this.options.el.getBoundingClientRect().top);

        if (this.state.below_nav) {
            this.options.el.classList.add('below-nav');
            this.options.body.classList.add('has-jump-nav');
        } else {
            this.options.el.classList.remove('below-nav');
            this.options.body.classList.remove('has-jump-nav');
        }

        window.requestAnimationFrame(() => { this.checkScroll(); });
    }
}

export default JumpNav;