let wp_tables = document.querySelectorAll('.wp-block-table table');

wp_tables.forEach(table => {
  let columns = table.querySelectorAll('th');
  let column_labels = [];
  
  columns.forEach((column, i) => {
    column_labels[i] = column.textContent;
  });
  
  let rows = table.querySelectorAll('tbody tr');
  
  rows.forEach(row => {
    let columns = row.querySelectorAll('td');
  
    columns.forEach((column, i) => {
      column.dataset.label = column_labels[i];
    });
  });
});