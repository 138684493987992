const toggles = document.querySelectorAll('.sub-menu .menu-item-has-children > a');

for(let toggle of toggles) {
  toggle.addEventListener('click', (e) => {toggleMenuActive(e, toggle)});
}

function toggleMenuActive(e, toggle) {
  if ( e.target.classList.contains( 'static-to-dynamic' ) ) {
	  return;
  }
  e.preventDefault();
  const parent = toggle.parentElement;
  const menu = parent.querySelector('ul');
  menu?.classList?.toggle('active');
}