export function initShowMorePosts() {
  const showMoreButtons = document.querySelectorAll('[data-show-more-posts]');
  const articleHolder = document.getElementById('infinite-post-contents');

  for(let button of showMoreButtons) {
    button.addEventListener('click', (e) => {
      e.preventDefault();

      if(articleHolder.classList.contains('is-loading')) {
        return;
      }

      getMorePosts();
    });
  }

  function getMorePosts() {
    if(!window.dealerResources) {
      return;  
    }
    const api_url = new URL(`${window.dealerResources.api_domain}/wp-json/dealer-resources/get-posts`);

    if(window.dealerResources?.post_types) {
      api_url.searchParams.append("post_types", window.dealerResources.post_types.join(','));
    }

    if(window.dealerResources?.terms) {
      api_url.searchParams.append("terms", window.dealerResources.terms.join(','));
    }

    if(window.dealerResources?.is_featured) {
      api_url.searchParams.append("is_featured", true);
    }

    if(window.dealerResources?.per_page) {
      api_url.searchParams.append("per_page", window.dealerResources?.per_page);
    }

    api_url.searchParams.append("page", Number(window.dealerResources?.page) + 1 || 2);
    window.dealerResources.page = Number(window.dealerResources?.page) + 1; 

    articleHolder.classList.add('is-loading');

    fetch(api_url.href).then((response) => {
      return response.text();
    }).then((html) => {
      articleHolder.innerHTML += html;

      window.setTimeout(() => {
        if(window.dealerResources.post_count <= window.dealerResources.page * window.dealerResources.per_page) {
          for(let button of showMoreButtons) {
            button.parentElement.classList.add('hidden');
          }
        }

        const articles = articleHolder.querySelectorAll('.is-loading');
        for(let article of articles) {
          article.classList.remove('is-loading');
        }
      }, 300);

      articleHolder.classList.remove('is-loading');
    });
  }
}