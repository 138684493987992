import imagesloaded from 'imagesloaded';

export default function (Glide, Components, Events) {
  const AUTOHEIGHT = {
    mount () {
      Components.Html.track.style.transition = 'height 0.6s cubic-bezier(0.165, 0.840, 0.440, 1.000)'
      AUTOHEIGHT.set()

      let slider_images = [];

      Components.Html.slides.forEach(slide => {
        let slider_image = slide.querySelector('img.lazyload');

        if (slider_image) {
          slider_images.push(slider_image);
        }
      });

      imagesloaded(Components.Html.track, () => {
        AUTOHEIGHT.set()
      })

      slider_images.forEach(image => {
          image.addEventListener('load', e => {
              setTimeout(() => {
                AUTOHEIGHT.set()
              }, 30);
          });
      });
    },

    set () {
      Components.Html.track.style.height = `${Components.Html.slides[Glide.index].offsetHeight}px`
    }
  }

  Events.on(['run', 'resize', 'run.after', 'build.after'], () => {
    AUTOHEIGHT.set()
  })

  return AUTOHEIGHT
}