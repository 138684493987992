let alert_bar_buttons = document.querySelectorAll('[data-alert-bar-dismiss]');

alert_bar_buttons.forEach(button => {
    button.addEventListener('click', (e) => {
        e.preventDefault();

        let alert_bar_value = button.dataset.alertBarDismiss;
        let alert_bar_cookie = button.dataset.alertBarCookie;
        let alert_bar = document.querySelectorAll('[data-alert-bar="' + alert_bar_value + '"]');

        alert_bar.forEach(alert => {
            alert.remove();
        });

        setCookie(alert_bar_cookie, 3, 7);
    });
});

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

    console.log(document.cookie);
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return 0;
}