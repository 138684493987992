const scrollToContainingBlock = function(el) {
    let containing_block = el.closest('[data-root-block]') || document.querySelector('body');
    let scroll_target = containing_block.offsetTop;

    window.scrollTo({
        top: scroll_target,
        left: 0,
        behavior: 'smooth'
    });
}

export default scrollToContainingBlock;