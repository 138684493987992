const inviews = document.querySelectorAll('[data-inview]');

if ("IntersectionObserver" in window) {
    const options = {
        threshold: 0.8
    }
    const inview_observer = new IntersectionObserver(onInview, options);

    inviews.forEach((inview) => {
        inview_observer.observe(inview);
    });

    function onInview(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('in-view');
     
                if(entry.target.dataset.inviewReplay) {
                    entry.target.dataset.inview = window.setInterval(() => {
                        entry.target.classList.remove('in-view');

                        window.setTimeout(() => {
                            entry.target.classList.add('in-view');
                        }, 100);
                    }, parseInt(entry.target.dataset.inviewReplay));
                }
            }
            else {
                entry.target.classList.remove('in-view');

                if(entry.target.dataset.inview) {
                    window.clearInterval(parseInt(entry.target.dataset.inview));
                    entry.target.dataset.inview = '';
                }
            }
        });
    }
}
else {
    inviews.forEach((inview) => {
        inview.classList.add('in-view');
    });
}
